import request from "@/utils/request";

// APP公开课列表
export function OpenClassList(params) {
    return request({
        url: '/admin/french/openClassList',
        method: 'get',
        params
    })
}

// 添加APP公开课
export function OpenClassAdd(data) {
    return request({
        url: '/admin/french/openClassAdd',
        method: 'post',
        data
    })
}

// 编辑APP公开课
export function OpenClassEdit(data) {
    return request({
        url: '/admin/french/openClassEdit',
        method: 'put',
        data
    })
}

// 删除APP公开课
export function OpenClassDel(data) {
    return request({
        url: '/admin/french/openClassDel',
        method: 'delete',
        data
    })
}

// 公开课上传COS签名
export function getOpenClassCosToken(params) {
    return request({
        url: '/admin/french/getOpenClassCosToken',
        method: 'get',
        params
    })
}
